.application-info .ant-card-head-title {
  padding-left: 24px;
  padding-right: 24px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  font-weight: 500;
  font-size: 20px;
}

.application-info .ant-card {
  margin-bottom: 16px;
}

p {
  margin-bottom: 6px;
}

p:last-child {
  margin-bottom: 0;
}

.app-sub-title {
  font-weight: 500;
  font-size: 15px;
}

.application-info .ant-list-item {
  display: block;
}

.sub-title {
  font-weight: 500;
  font-size: 18px;
  margin-bottom: 0;
  margin-top: 3px;
}

.documents-list .ant-list-items {
  display: flex;
  padding: 10px;
  flex-wrap: wrap;
}

.documents-list .ant-list-item {
  border: 0;
  padding: 12px;
}

.documents-list .ant-list-item img {
  height: 130px;
  object-fit: cover;
}

.custom-site-header {
  -ms-justify-content: center;
  justify-content: center;
}

.custom-site-logo {
  display: block;
  max-width: 50%;
}